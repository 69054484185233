.highlightbox {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding: 24px 48px;
    margin-bottom: 48px;
    max-width: 1200px;
    font-size: 14px;
}

.highlightbox .question-button{
    flex-grow: 1;
    order: 99;
}

.highlightbox .question-button:hover{
    text-decoration: none;
}

.highlightbox .question-text{
    flex-grow: 5;
    order: 0;
}

.bg-amber-2 {
    background: #ffecb3!important;
}

.no_margin{
    margin: 0;
}

.q-btn__wrapper {
    font-weight: 500;
    padding: 8px 16px;
    min-height: 2.572em;
    border-radius: 3px;
    width: 100%;
    height: 100%;
    color: #1565c0;
    border: 1px solid #1565c0;
    background-color: transparent;
}

.q-btn__wrapper:hover{
    background-color: rgba(21, 101, 192, 0.15);
    text-decoration: none;
}